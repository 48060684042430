$sizes: 32;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 1px;

    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin !important;
    }

    .ml-#{$i} {
      margin-left: $margin !important;
    }

    .mr-#{$i} {
      margin-right: $margin !important;
    }

    .mt-#{$i} {
      margin-top: $margin !important;
    }

    .mb-#{$i} {
      margin-bottom: $margin !important;
    }

    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }

    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 1px;

    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding;
    }

    .pl-#{$i} {
      padding-left: $padding;
    }

    .pr-#{$i} {
      padding-right: $padding;
    }

    .pt-#{$i} {
      padding-top: $padding;
    }

    .pb-#{$i} {
      padding-bottom: $padding;
    }

    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }

    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
