/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

@import './theme/mixins';

@include margin-classes;
@include padding-classes;

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

* {
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0;
}

ion-content {
  --background: #fafafa;

  @media only screen and (max-width: 768px) {
    --background:#fff !important;
  }
}

ion-title {
  font-size: 1.5rem !important;
  padding-left: 0 !important;
  font-weight: 600 !important;

  @media only screen and (max-width: 768px) {
    font-size: 1.2rem !important;
  }

}

ion-label{
  .red{
    color: red;
  }
}


ion-row,
ion-col {
  padding: 0;
}

.content-container {
  padding: 5% 5%;
}

@media only screen and (min-width: 768px) {
  .content-container {
    padding: 2%;
    margin-left: 70px;
  }

}

.static-title {
  margin-bottom: 20px;
}

ion-item {
  --background: transparent;
  background: transparent;
}

ion-searchbar {
  input {
    min-height: 40px !important;
    padding:0 !important;
    padding-left: 30px !important;
  }

  ion-icon {
    left: 2px !important;
    top:9px !important;
  }
}

.logo {
  max-width: 60px;

  @media only screen and (max-width: 768px) {
    max-width: 42px !important;
    margin-right: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
}

h1 {
  font-size: 1.86rem !important;
}

h2 {
  font-size: 1.5rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 1.3rem !important;
  }
}

h3 {
  font-size: 1.4rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem !important;
  }
}

h4 {
  font-size: 1.15rem !important;
}

h5 {
  font-size: 1rem !important;
}

h6 {
  font-size: 0.8rem !important;
}

p {
  font-size: 1rem !important;
  color: var(--body) !important;
}

.extralight-font {
  font-weight: 200 !important;
}

.light-font {
  font-weight: 300 !important;
}

.regular-font {
  font-weight: 400 !important;
}

.medium-font {
  font-weight: 500 !important;
}

.semibold-font {
  font-weight: 600 !important;
}

.bold-font {
  font-weight: 700 !important;
}

.extrabold-font {
  font-weight: 800 !important;
}

ion-button {
  --background: var(--ion-color-primary);
  color: var(--ion-color-secondary-contrast);
  --box-shadow: none;
  min-height: 50px;
  --border-radius:8px !important;
  --padding-start: 20px;
  --padding-end: 20px;
  text-transform: none;
  margin: 0;

  &.noborder {
    border: none !important;
    --border: none !important;
  }

  &.invert {
    --background: transparent !important;
    color: var(--ion-color-primary);
  }
}

ion-input{
  border: 1px solid var(--ion-color-secondary) !important;
  --background: #fff !important;
  border-radius: 8px !important;
  height: 55px;
  --padding-start:15px !important;
  --padding-end:15px !important;
}

ion-textarea {
  border: 1px solid var(--ion-color-secondary) !important;
  --background: #fff !important;
  border-radius: 8px !important;
  --padding-start:15px !important;
  --padding-end:15px !important;
}


ion-item {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

cdk-virtual-scroll-viewport {
  width: 100%;
  height: 100%;

  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}

.transparent-alert {
  --backdrop-opacity: 0;
  --background: transparent;
}

.error-message {
  color: var(--ion-color-danger);
  display: inline-block;
  font-size: 12px;
}

.addContactModal {
  ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
}

ion-modal {
  --border-radius: 16px;
}

.mini-profile {
  ion-avatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    background: #f4f4f4;
  }
  margin-bottom: 20px;
}

.custom-card {
  border: 1px solid #e6e6e6;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;

  .card-title {
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  label {
    margin-bottom: 5px;
    display: block;
    margin-top: 15px;
  }

  ion-button {
    margin-top: 20px;
  }
}

.info-box {
  --background: #eff3f6;
  background: #eff3f6;
  border-radius: 8px;
  padding: 10px 15px;
  --inner-padding-end: 0;
  --inner-padding-start: 0;

  max-width: 550px;

  ion-icon {
    font-size: 60px;
    margin-right: 15px;
  }
}

.sidebar {
  background: #fff;
  height: calc(100vh - 87px);
  padding: 2vw;
  border-left: 1px solid #e6e6e6;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.03);
  position: sticky;
  top:0;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &.horizontal {
    height: auto !important;
  }
}

ion-modal {
  padding: 0 20px;

}

.modal-upload-document {
  --height: 27rem !important;
}

.modal-add-signature {
  --height: 40rem !important;
  --width: 30rem !important;

  @media only screen and (max-width: 768px) {
    --height: 39rem !important;
  }
}


.modal-verification {

  background: transparent !important;
  --background:transparent !important;
  --box-shadow:0 !important;
  box-shadow:0 !important;

  .modal-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    margin: auto;
    border-radius: 8px;
    max-width: 500px;

  }

}


.modal-upload-sucess {

  background: transparent !important;
  --background:transparent !important;
  --box-shadow:0 !important;
  box-shadow:0 !important;

  .modal-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    margin: auto;
    border-radius: 8px;
    max-width: 500px;
  }

}

.modal-topup {

  background: transparent !important;
  --background:transparent !important;
  --box-shadow:0 !important;
  box-shadow:0 !important;
  –height: auto;

  .modal-wrapper{
    min-height: 610px;
    height: auto;
    –height: auto;
  }

  .modal-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    margin: auto;
    border-radius: 8px;
    max-width: 500px;

  }

}

.modal-edit-contact {

  background: transparent !important;
  --background:transparent !important;
  --box-shadow:0 !important;
  box-shadow:0 !important;

  .modal-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    margin: auto;
    border-radius: 8px;
    max-width: 500px;
  }

}


.submitdocwrapper {
  text-align: right;
  padding: 10px 2%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  .icon {
    background: var(--ion-color-primary);
    padding: 8px 13px;
    border-radius: 8px;
    color:#fff;
  }

  .currentPage {
    color: var(--ion-color-primary);
    text-align: center;
    font-weight: 800;
  }

  .nextPage {
    text-align: center;
    color:#000;
  }

  .leftArrowIcon {
    margin-right: 25px;
    cursor: pointer;
  }

  .rightArrowIcon {
    margin-left: 25px;
    cursor: pointer;
  }

  .paginationText {
    padding: 8px;
    color: var(--ion-color-medium);
  }
}

.modal-add-signer {
  --width: 32rem;
  --height: 38rem !important;
}

.modal-add-member {
  --height: 46rem !important;
  --width: 38rem;
}

.modal-magic-link{
  --width: 29rem;
  --height: 27rem !important;
}

.modal-verification-resend{

  background: transparent !important;
  --background:transparent !important;
  --box-shadow:0 !important;
  box-shadow:0 !important;

  .modal-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    margin: auto;
    border-radius: 8px;
    max-width: 500px;

  }
}


/* scrollbar */

::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end of scrollbar*/



.status {

  display: inline-block;
  vertical-align: middle;
  background: var(--ion-color-completed-background);
  border-radius: 8px;
  padding: 5px 15px;
  font-weight: 600;
  color: var(--ion-color-completed);


  &.COMPLETED, &.SUCCESS {
    color: var(--ion-color-medium-contrast) !important;
    background: var(--ion-color-completed-background);
  }

  &.draft {
    color: var(--ion-color-medium-contrast) !important;
    background: var(--ion-color-draft-background);
  }

  &.WAITING {
    color: var(--ion-color-medium-contrast) !important;
    background: var(--ion-color-waiting-background);
  }

  &.PENDING, &.EXPIRE, &.FAILED {
    color: var(--ion-color-medium-contrast) !important;
    background: var(--ion-color-pending-background);
  }

  &.ARCHIVED {
    color: var(--ion-color-medium-contrast) !important;
    background: var(--ion-color-archived-background);
  }

  &.DECLINED {
    color: black !important;
    background: white;
  }

}

.alert-radio-label{
  white-space: normal !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*IPHONE X WAKTU BUILD IOS INI DINYALAKAN*/

@media (max-width: 430px) {
  .ios ion-tab-bar {
    padding-bottom: 20px !important;
  }

  .ios .userwelcome {
    top: 40px !important;
  }

  .ios ion-header {
    padding-top: 40px !important;
  }

  .ios ion-footer {
    padding-bottom: 30px !important;
  }

  .ios .floatingbtn {
    bottom: 21px !important;
  }
}

@media (min-width:768px) {

  .ios ion-header {
    padding-top: 30px !important;
  }

}

/* END OF IOS IPHONE SETTING CSS */


.titlewithinfo {
  display: flex !important;
  align-items: center;

  ion-icon {
    font-size: 20px !important;
    margin-left: 10px;
  }
}


#qr-reader button{
  padding: 20px !important;
  border-radius: 8px !important;
  width: 100% !important;
  background: var(--ion-color-primary) !important;
  color:#fff;
}

#qr-reader {
  border: none !important;
}

.modal-instruction{
  --width: 100% !important;
  --height: 100% !important;
  padding: 0px !important;
  --border-radius: 0px !important;
}
